import React, { createContext, useContext } from "react";

const FeatureContext = createContext({});

export const useFeature = () => useContext(FeatureContext);

export const FEATURE_FLAGS = {
  showChartsOnDashboard: true,
  isTribeFeatureActive: true,
  allowToAddTAX: false,
  ticketTypeFeature: {
    allowComboTicketType: true,
    allowGroupTicketType: true,
    allowToCreateNFTTicket: false,
  },
};

function FeatureProvider(props) {
  return <FeatureContext.Provider value={FEATURE_FLAGS}>{props.children}</FeatureContext.Provider>;
}

export default FeatureProvider;
