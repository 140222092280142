import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { history } from "./store";
import App from "./containers/index";
import { MsalProvider } from "@azure/msal-react";
import { h7Application } from "utils-auth/authService";
import AuthProvider from "./context/AuthProvider";
import FeatureProvider from "./context/FeatureProvider";
import "react-quill/dist/quill.snow.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import RouteProvider from "context/RouteContext";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "components/Notification";
import TenantProvider from "context/TenantContext";
import SeedProvider from "context/SeedContext";
import GeographyProvider from "context/GeographyContext";
import * as Sentry from "@sentry/react";

const MainApp = () => {
  return (
    <MsalProvider instance={h7Application}>
      <RouteProvider history={history}>
        <AuthProvider history={history}>
          <FeatureProvider>
            <Router history={history}>
              <SnackbarProvider anchorOrigin={{ horizontal: "left", vertical: "bottom" }} autoHideDuration={3000} maxSnack={3} hideIconVariant>
                <Switch>
                  <SeedProvider>
                    <GeographyProvider>
                      <TenantProvider>
                        <Route path="/" component={App} />
                      </TenantProvider>
                    </GeographyProvider>
                  </SeedProvider>
                </Switch>
                <SnackbarUtilsConfigurator />
              </SnackbarProvider>
            </Router>
          </FeatureProvider>
        </AuthProvider>
      </RouteProvider>
    </MsalProvider>
  );
};

export default Sentry.withProfiler(MainApp);
