import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./MenuItem.module.scss";
import Icons from "components/Common/Icons";
import IntlMessages from "util/IntlMessages";

function MenuItem(props) {
  const {
    isSelected,
    showList,
    isSubMenu,
    setShowList,
    onClick,
    iconType,
    title,
    isSubComponent,
    isNestedComponent,
    // comment
  } = props;

  return (
    <div
      className={classNames(styles.menuItem, {
        [styles.selectedMenuItem]: isSelected && !showList,
        [styles.subMenuItem]: isSubComponent,
        [styles.nestedSubMenuItem]: isNestedComponent,
      })}
      onClick={isSubMenu ? () => setShowList(!showList) : onClick}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.iconTitleWrapper}>
          <Icons type={iconType} size="xl" className={styles.icon} />
          <span className={classNames(styles.title, "truncate")}>
            <IntlMessages id={title} />
          </span>
        </div>
        {isSubMenu && (
          <Icons
            type="rightArrow"
            className={classNames(styles.expandIcon, {
              [styles.show]: showList,
            })}
            size="lg"
          />
        )}
      </div>
      {isSelected && <div className={styles.sideRect} />}
    </div>
  );
}
export default MenuItem;

MenuItem.propTypes = {
  isSelected: PropTypes.bool,
  showList: PropTypes.bool,
  isSubMenu: PropTypes.bool,
  setShowList: PropTypes.func,
  onClick: PropTypes.func,
  iconType: PropTypes.string,
  title: PropTypes.string,
  isSubComponent: PropTypes.bool,
  isNestedComponent: PropTypes.bool,
};

MenuItem.defaultProps = {
  isSelected: false,
  showList: false,
  isSubMenu: false,
  setShowList: () => {},
  onClick: () => {},
  iconType: "",
  title: "",
  isSubComponent: false,
  isNestedComponent: false,
};
