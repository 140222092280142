import React from "react";
import { Link, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import LanguageSelector from "components/LanguageSelector";
import CopyComponent from "components/CopyComponent";
import sidebarNavStore from "containers/SideNav/sideNavStore";
import { useTenantContext } from "context/TenantContext";
import Icons from "components/Common/Icons";
import UserInfo from "components/UserInfo";
import Tooltip from "components/Tooltip";

function Header() {
  const { tenantId, tenantName, eventDomainName, logo } = useTenantContext();
  const { toggleCollapsedNav } = sidebarNavStore;

  const openEventPage = () => {
    window.open(`https://${eventDomainName}`, "_blank");
  };

  return (
    <div className="app-main-header md:py-2">
      <div className="flex h-[70px] min-h-[70px] max-h-[70px] text-gray-900 px-[1rem] md:px-6">
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-2 items-center">
            <Icons type="menu" className="block lg:hidden" aria-label="Menu" onClick={toggleCollapsedNav} size="5xl" />
            <div className="hidden md:block">
              {logo?.imageUrl ? (
                <img src={logo?.imageUrl} alt={tenantName} title={tenantName} className="h-12 w-12 rounded-full object-cover bg-blankImage" />
              ) : (
                <div className="bg-neutrals7 p-2 h-12 w-12 rounded-full flex items-center justify-center typo-20-500 !text-primary">{tenantName?.charAt(0)}</div>
              )}
            </div>
            <div className="flex flex-col">
              <span className="typo-24-600 cursor-pointer" onClick={openEventPage}>
                {tenantName}
              </span>
              {tenantName && (
                <div className="hidden lg:block">
                  <CopyComponent showTitle={false} iconSize="xs" value={tenantId} iconClassName="!text-[#677990] hover:text-green" valueClassName="typo-14-400 text-[#677990]" />
                </div>
              )}
              {tenantName && (
                <div className="block lg:hidden">
                  <CopyComponent
                    showTitle={false}
                    value={eventDomainName}
                    iconSize="xs"
                    iconClassName="!text-[#677990] hover:text-green"
                    onValueClick={openEventPage}
                    valueClassName="link-button typo-14-400 text-[#677990]"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="hidden lg:block">
            {tenantName && (
              <div className="btn-outline-drop p-2">
                <CopyComponent
                  title={<IntlMessages id="component.header.linkTitle" />}
                  value={eventDomainName}
                  onValueClick={openEventPage}
                  valueClassName="link-button typo-14-400"
                  iconClassName="!text-[#677990] hover:text-green"
                />
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className="flex gap-2">
              <Tooltip
                menuButton={
                  <Link to="/tenants-list">
                    <div className="w-10 h-10 rounded-full text-[#677990] bg-[#F6F7F9] flex items-center justify-center hover:text-neutrals9 hover:bg-primary">
                      <Icons type="earth" size="2xl" />
                    </div>
                  </Link>
                }
                message={<IntlMessages id="app.components.header.sites" />}
                horizontalPosition="center"
                verticalPosition="top"
                size="sm"
              />
              <div className="hidden md:flex">
                <LanguageSelector />
              </div>
            </div>
            <div className="hidden md:flex">
              <UserInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
