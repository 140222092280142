import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MenuItem from "../MenuItem";
import styles from "./SidebarItem.module.scss";

function SidebarItem(props) {
  const { item, handleRedirect, pathname, isSubComponent, isNestedComponent, checkPathActive } = props;
  const isSubMenuSelected = item?.subMenu?.some((i) => i.link === pathname);
  const isNestedSubMenuSelected = item?.subMenu?.some((i) => i?.subMenu?.some((i) => i.link === pathname));
  const initialShowList = isSubMenuSelected || isNestedSubMenuSelected || false;
  const [showList, setShowList] = useState(initialShowList);
  const isSubMenu = item.subMenu && item.subMenu.length > 0 && item.subMenu.some((i) => !i.hide);

  return (
    <div>
      <MenuItem
        isSelected={checkPathActive ? pathname.includes(item.link) : item.link === pathname}
        showList={showList}
        isSubMenu={isSubMenu}
        setShowList={setShowList}
        onClick={() => handleRedirect(item.link)}
        iconType={item.icon}
        title={item?.title}
        isSubComponent={isSubComponent}
        isNestedComponent={isNestedComponent}
      />
      {showList && isSubMenu && (
        <div className={classNames(styles.sidebarItems, styles.moveToDown)}>
          {item.subMenu.map(
            (subItem, index) =>
              !subItem.hide && <SidebarItem key={index} item={subItem} handleRedirect={handleRedirect} pathname={pathname} isSubComponent={!isNestedComponent} isNestedComponent={isSubComponent} />
          )}
        </div>
      )}
    </div>
  );
}

export default SidebarItem;

SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleRedirect: PropTypes.func,
  pathname: PropTypes.string,
  isSubComponent: PropTypes.bool,
  isNestedComponent: PropTypes.bool,
  checkPathActive: PropTypes.bool,
};

SidebarItem.defaultProps = {
  handleRedirect: () => {},
  pathname: "",
  isSubComponent: false,
  isNestedComponent: false,
  checkPathActive: false,
};
