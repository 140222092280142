import React from "react";
import PropTypes from "prop-types";
import UserInfo from "components/UserInfo";
import SidebarCategory from "../SidebarCategory";
import { menuList } from "../data";
import styles from "./SidenavContent.module.scss";
import Icons from "components/Common/Icons";
import { useRouter } from "context/RouteContext";
import LanguageSelector from "components/LanguageSelector";
import { useAuthContext } from "context/AuthProvider";
import sidebarNavStore from "containers/SideNav/sideNavStore";

function SidenavContent(props) {
  const { showCloseIcon } = props;
  const { toggleCollapsedNav } = sidebarNavStore;
  const { push, location } = useRouter();
  const { isSuperAdmin } = useAuthContext();

  function handleRedirect(link) {
    if (link) {
      push(link);
      if (showCloseIcon) {
        toggleCollapsedNav();
      }
    }
  }

  return (
    <div className={styles.sideBar}>
      <div className="w-full pt-5 px-[1.8rem]">
        <img src="/assets/images/logo.png" className="w-36" alt="logo" />
      </div>
      <div className="px-6 md:hidden">
        <UserInfo />
      </div>
      <SidebarCategory options={menuList({ isSuperAdmin })} pathname={location.pathname} handleRedirect={handleRedirect} />
      <div className="flex md:hidden mt-4 px-6">
        <LanguageSelector />
      </div>
      {showCloseIcon && (
        <div onClick={toggleCollapsedNav}>
          <Icons isIconButton containerClassName="absolute top-12 right-[-18px] bg-neutrals9 text-neutrals4 shadow-[0_1px_4px_rgba(51,51,51,0.3)] rounded-full" type="close" />
        </div>
      )}
    </div>
  );
}

export default SidenavContent;

SidenavContent.propTypes = {
  showCloseIcon: PropTypes.bool,
};

SidenavContent.defaultProps = {
  showCloseIcon: false,
};
