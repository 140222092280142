import React, { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import AuthServices, { h7Application } from "utils-auth/authService";
import { tokenRequest } from "utils-auth/AuthConfigAAD";
import MasterButton from "components/Common/MasterButton";
import { useRouter } from "context/RouteContext";
import { useAuthContext } from "context/AuthProvider";

const SignIn = () => {
  const router = useRouter();
  const { setIsAuthenticated, setRole, setName, setUserId } = useAuthContext();

  const [isLoginDone, setIsLoginDone] = useState(false);

  const handleSignIn = async () => {
    setIsLoginDone(true);
    await AuthServices.logIn();
  };

  useEffect(() => {
    const init = async () => {
      const token = localStorage.getItem("access_token");
      setIsLoginDone(true);

      if (!token) {
        try {
          const response = await h7Application.handleRedirectPromise();

          if (response != null) {
            const res = await AuthServices.getTokenRedirect(tokenRequest, response.account.homeAccountId);

            if (res) {
              localStorage.setItem("access_token", res.accessToken);
              localStorage.setItem("user_id", res.idTokenClaims.sub);

              setIsAuthenticated(true);
              setRole(res.idTokenClaims.userRoles);
              setName(res.idTokenClaims.name);
              setUserId(res.idTokenClaims.sub);

              setIsLoginDone(false);
              router.push("/tenants-list");
            }
          }
        } catch (error) {
          console.log(error);
        }
      }

      setIsLoginDone(false);
    };

    init();
  }, []);

  return (
    <div className="signIn-wrapper">
      <div className="w-[100vw] h-[100vh] relative">
        <img src="/assets/images/login-back-1.svg" className="w-full h-full bg-[#0A0F15]" />
        <img src="/assets/images/login-back-2.svg" className="absolute top-0 left-0" />
        <img src="/assets/images/login-back-3.svg" className="absolute bottom-0 right-0" />
        <img src="/assets/images/login-back-4.svg" className="absolute w-full h-full bottom-0 bg-opacity-20 bg-[#043253]" />
      </div>
      <div className="login-box gap-6 py-20">
        <img src="/assets/images/logo.png" className="w-40" alt="logo" />
        <MasterButton className="loginBtn" buttonText={<IntlMessages id="appModule.login" />} isLoading={isLoginDone} onClick={handleSignIn} />
      </div>
    </div>
  );
};

export default SignIn;
