import React from "react";
import PropTypes from "prop-types";
import styles from "./Loader.module.scss";
import classNames from "classnames";

const Loader = (props) => {
  const { className, size, color, style } = props;

  const dynamicStyles = {
    height: `${size}px`,
    width: `${size}px`,
    borderTopColor: color,
    borderLeftColor: color,
    borderRightColor: color,
  };

  return (
    <div className={classNames(className, styles.container)} style={style}>
      <div className={styles.loader} style={dynamicStyles}></div>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

Loader.defaultProps = {
  size: 26,
  className: "",
  color: "#3939ff",
  style: {},
};
