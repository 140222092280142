import { createUserManager } from 'redux-oidc';
import config from './config';

let baseUrlLocal = "";
if (config.environment === 'production') { baseUrlLocal = `${window.location.protocol}//${window.location.hostname}/#/`; } else { baseUrlLocal = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/#/`; }

// LIVE
const userManagerConfig = {
  authority: config.idSrvUrl,
  client_id: config.clientId,
  // redirect_uri: `${baseUrlLocal}callback#`,
  response_type: 'id_token token',
  scope: 'openid profile ht.catalogapi.admin ht.eventapi.admin ht.hotelapi.admin',
  // post_logout_redirect_uri: `${baseUrlLocal}signin`,
  // silent_redirect_uri: `${baseUrlLocal}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  // accessTokenExpiringNotificationTime: 3600,
};

// //QA
// const userManagerConfig = {
//   authority: config.idSrvUrl,
//   client_id: config.clientId,
//   redirect_uri: `${baseUrlLocal}callback#`,
//   response_type: 'id_token token',
//   scope: 'openid profile ht.catalogapi.admin ht.eventapi.admin ht.hotelapi.admin',
//   post_logout_redirect_uri: `${baseUrlLocal}signin`,
//   silent_redirect_uri: `${baseUrlLocal}/silent_renew.html`,
//   automaticSilentRenew: true,
//   filterProtocolClaims: true,
//   loadUserInfo: true,
//   // accessTokenExpiringNotificationTime: 3600,
// };

// const userManagerConfig = {
//   authority: config.idSrvUrl,
//   client_id: 'super',
//   redirect_uri: `${window.location.protocol}//${window.location.hostname}/admin/#/callback#`, //'http://blox.hypertrends.com/admin/#/callback#',
//   response_type: 'id_token token',
//   scope: 'openid profile ht.catalogapi.admin ht.eventapi.admin ht.hotelapi.admin',
//   post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}/admin/#/`,
//   silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}/admin/#/silent_renew.html`,
//   automaticSilentRenew: true,
//   filterProtocolClaims: true,
//   loadUserInfo: true,
// };

const userManager = createUserManager(userManagerConfig);

export default userManager;

// https://coreapps.hypertrends.com/IdSrv/oauth-connector?tenant=localhost:3000
