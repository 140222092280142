import config from "./config";

export const msalConfig = {
  auth: {
    clientId: config.aad.aadClientId,
    authority: config.aad.signUpSignInPolicyUrl,
    knownAuthorities: [config.aad.aadDomain],
    redirectUri: config.aad.redirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["openid", "profile"],
};

export const tokenRequest = {
  scopes: [...config.aad.apiScopes],
  forceRefresh: false,
};
