import { useMemo } from "react";
import { useQuery } from "react-query";
import { getContinents, getContinentsByCountry, getCountries, getStatesByCountry } from "services-manager/geographyManager";

export function useGeographyData(tenantId, userId, isAuthenticated, selectedDetails) {
  const { selectedCountryCode, continentByCountry } = selectedDetails;
  const isUserAuthorized = isAuthenticated && !!userId;

  const {
    data: countries,
    isLoading: isCountryLoading,
    isSuccess: isCountrySuccess,
  } = useQuery([{ key: `/api/countries` }], getCountries, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized && !!tenantId,
    keepPreviousData: true,
  });

  const countryOptions = useMemo(
    () => (isCountrySuccess && countries?.items?.length > 0 ? countries?.items.map((countryDetails) => ({ label: countryDetails?.name, value: countryDetails?.id })) : []),
    [isCountrySuccess, countries]
  );

  const {
    data: states,
    isLoading: isStatesLoading,
    isSuccess: isStatesSuccess,
  } = useQuery([selectedCountryCode, `/countries/${selectedCountryCode}/state-provinces`], getStatesByCountry, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized && !!selectedCountryCode,
  });

  const stateOptions = useMemo(
    () =>
      isStatesSuccess && !!selectedCountryCode && states?.items?.length > 0
        ? states?.items.map((stateDetails) => ({ label: stateDetails?.longName, value: stateDetails?.code, countryCode: stateDetails?.countryCode }))
        : [],
    [isStatesSuccess, states?.items, selectedCountryCode]
  );

  const { data: continents, isLoading: isContinentLoading } = useQuery([{ key: `/api/continents` }], getContinents, {
    refetchOnWindowFocus: false,
    enabled: isUserAuthorized && !!tenantId,
    keepPreviousData: true,
  });

  const continentOptions = useMemo(
    () => (continents?.items?.length > 0 ? continents?.items.map((continentDetails) => ({ label: continentDetails?.name, value: continentDetails?.code })) : []),
    [continents?.items]
  );

  const { data: countriesByContinent, isLoading: isCountriesByContinentLoading } = useQuery([continentByCountry, { key: `/api/continents/${continentByCountry}/countries` }], getContinentsByCountry, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && !!continentByCountry,
  });

  const countriesByContinentOptions = useMemo(
    () => (countriesByContinent?.items?.length > 0 ? countriesByContinent?.items.map((continentDetails) => ({ label: continentDetails?.name, value: continentDetails?.id })) : []),
    [countriesByContinent?.items]
  );
  const geographyData = useMemo(
    () => ({
      countryOptions: countryOptions,
      isCountryLoading: isCountryLoading,
      stateOptions: stateOptions,
      isStatesLoading: isStatesLoading,
      isStatesSuccess: isStatesSuccess,
      continentOptions: continentOptions,
      isContinentLoading: isContinentLoading,
      countriesByContinentOptions: countriesByContinentOptions,
      isCountriesByContinentLoading: isCountriesByContinentLoading,
    }),
    [countryOptions, isCountryLoading, stateOptions, isStatesLoading, isStatesSuccess, continentOptions, isContinentLoading, countriesByContinentOptions, isCountriesByContinentLoading]
  );

  return geographyData;
}
