import React from "react";
import Loader from "components/Loader";

function TenantLoader() {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <Loader />
    </div>
  );
}

export default TenantLoader;
