import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { ControlledMenu, useMenuState } from "@szhsin/react-menu";
import classNames from "classnames";

function Tooltip(props) {
  const { verticalPosition, horizontalPosition, menuButton, message, messageClassName, showChildren, children, size, isDisabled, customClasses, isPortal, menuClassName } = props;
  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: false });

  const handleToggle = (value) => () => {
    if (isDisabled) return;
    toggleMenu(value);
  };

  const handleClick = (value) => () => {
    if (isDisabled) return;
    toggleMenu(value === "closed");
  };

  return (
    <Fragment>
      <div ref={ref} className={classNames("overflow-hidden", menuClassName)} onMouseEnter={handleToggle(true)} onMouseLeave={handleToggle(false)} onClick={handleClick(menuProps.state)}>
        {menuButton}
      </div>
      <ControlledMenu
        direction={verticalPosition}
        align={horizontalPosition}
        arrow
        portal={isPortal}
        className={
          customClasses ||
          classNames({
            "react-menu-tooltip": size === "lg",
            "react-menu-tooltip-sm": size === "sm",
          })
        }
        anchorRef={ref}
        {...menuProps}
      >
        {showChildren ? (
          children
        ) : (
          <div
            className={classNames("py-1 px-2 text-center", messageClassName, {
              "text-14 font-normal": size === "lg",
              "text-12 font-normal": size === "sm",
            })}
          >
            {message}
          </div>
        )}
      </ControlledMenu>
    </Fragment>
  );
}

export default Tooltip;

Tooltip.propTypes = {
  verticalPosition: PropTypes.string,
  horizontalPosition: PropTypes.string,
  menuButton: PropTypes.element,
  message: PropTypes.string,
  messageClassName: PropTypes.string,
  showChildren: PropTypes.bool,
  children: PropTypes.element,
  size: PropTypes.oneOf(["sm", "lg"]),
  isDisabled: PropTypes.bool,
  customClasses: PropTypes.string,
  isPortal: PropTypes.bool,
  menuClassName: PropTypes.string,
};

Tooltip.defaultProps = {
  verticalPosition: "top",
  horizontalPosition: "center",
  menuButton: null,
  message: "",
  messageClassName: "",
  showChildren: false,
  children: null,
  size: "lg",
  isDisabled: false,
  customClasses: "",
  isPortal: true,
  menuClassName: "",
};
