import React from "react";
import Loader from "components/Loader";

function CallbackPage() {
  return (
    <div className="app-login-container flex justify-center items-center animated slideInUpTiny animation-duration-3">
      <div className="loader-view">
        <Loader />
      </div>
    </div>
  );
}

export default CallbackPage;
