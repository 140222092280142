import React, { createContext, useContext, useMemo, useState } from "react";
import { useAuthContext } from "./AuthProvider";

import { useGeographyData } from "hooks/useGeographyData";

export const GeographyContext = createContext({});

export const useGeographyContext = () => useContext(GeographyContext);

function GeographyProvider(props) {
  const { userId, isAuthenticated } = useAuthContext();
  const tenantId = localStorage.getItem("tenant_id");

  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [continentByCountry, setContinentByCountry] = useState("");

  const { countryOptions, isCountryLoading, stateOptions, isStatesLoading, isStatesSuccess, continentOptions, isContinentLoading, countriesByContinentOptions, isCountriesByContinentLoading } =
    useGeographyData(tenantId, userId, isAuthenticated, { selectedCountryCode, continentByCountry });

  const getStatesByCountryCode = (countryCode) => {
    setSelectedCountryCode(countryCode);
  };

  const getCountryByContinent = (continent) => {
    setContinentByCountry(continent);
  };

  const value = useMemo(
    () => ({
      isContinentLoading: isContinentLoading,
      continents: continentOptions,
      countries: countryOptions,
      countriesByContinent: countriesByContinentOptions,
      states: stateOptions,
      isStatesLoading: isStatesLoading,
      isStatesSuccess: isStatesSuccess,
      isCountryLoading: isCountryLoading,
      isCountriesByContinentLoading: isCountriesByContinentLoading,
      getCountryByContinent: getCountryByContinent,
      getStatesByCountryCode: getStatesByCountryCode,
    }),
    [stateOptions, isStatesLoading, isStatesSuccess, isCountryLoading, isCountriesByContinentLoading, countryOptions, isContinentLoading, continentOptions, countriesByContinentOptions]
  );

  return <GeographyContext.Provider value={value}>{props.children}</GeographyContext.Provider>;
}

export default GeographyProvider;
