import React, { useState, useEffect, useRef } from "react";
import Nprogress from "nprogress";
import ReactPlaceholder from "react-placeholder";
import "nprogress/nprogress.css";
import "react-placeholder/lib/reactPlaceholder.css";

function asyncComponent(importComponent) {
  return function AsyncFunc(props) {
    const [component, setComponent] = useState(null);
    const mountedRef = useRef(true);

    useEffect(() => {
      Nprogress.start();
      return () => {
        mountedRef.current = false;
      };
    }, []);

    useEffect(() => {
      const fetchComponent = async () => {
        try {
          const { default: Component } = await importComponent();
          Nprogress.done();
          if (mountedRef.current) {
            setComponent(<Component {...props} />);
          }
        } catch (error) {
          console.error("Error loading component:", error);
        }
      };

      fetchComponent();

      return () => {
        mountedRef.current = false;
      };
    }, [props]);

    const ComponentToRender = component || <div />;

    return (
      <ReactPlaceholder type="text" rows={7} ready={ComponentToRender !== null}>
        {ComponentToRender}
      </ReactPlaceholder>
    );
  };
}

export default asyncComponent;
