import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNIN_IDENTITY_USER,
  SIGNIN_IDENTITY_USER_SUCCESS,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  SIGNUP_PROCESS_DONE,

  GET_USER_TENANTS,
  GET_USER_TENANTS_SUCCESS,
  GET_USER_TENANTS_ERROR,
  // USER CLAIMS
  GET_USER_CLAIMS,
  GET_USER_CLAIMS_SUCCESS,
  GET_USER_CLAIMS_ERROR
} from 'constants/ActionTypes';

export const userIdentitySignIn = user => ({
    type: SIGNIN_IDENTITY_USER,
    authUser: user,
  });

export const userIdentitySignInSuccess = authUser => ({
    type: SIGNIN_IDENTITY_USER_SUCCESS,
    payload: authUser,
  });

export const getUserInfo = () => ({
    type: GET_USER_INFO,
  });

export const getUserInfoSuccess = userInfo => ({
    type: GET_USER_INFO_SUCCESS,
    userInfo: {
      given_name: userInfo.givenName || userInfo.given_name,
      family_name: userInfo.familyName || userInfo.family_name,
      sub: userInfo.objectId || userInfo.sub,
      displayName: userInfo.displayName || userInfo.name,
      // email: userInfo.signInNames.emailAddress
    },
  });

export const userSignOut = () => ({
    type: SIGNOUT_USER,
  });


export const userSignOutSuccess = () => ({
    type: SIGNOUT_USER_SUCCESS,
  });

export const showAuthMessage = message => ({
    type: SHOW_MESSAGE,
    payload: message,
  });

export const setInitUrl = url => ({
    type: INIT_URL,
    payload: url,
  });

export const showAuthLoader = () => ({
    type: ON_SHOW_LOADER,
  });
export const signupProcessDone = () => ({
    type: SIGNUP_PROCESS_DONE,
  });

export const hideMessage = () => ({
    type: HIDE_MESSAGE,
  });
export const hideAuthLoader = () => ({
    type: ON_HIDE_LOADER,
  });

export const getUserTenants = (userId, searchTerm, skip, take) => ({
    type: GET_USER_TENANTS,
    userId,
    searchTerm,
    skip,
    take
  });
export const getUserTenantsSuccess = data => ({
    type: GET_USER_TENANTS_SUCCESS,
    data
  });
export const getUserTenantsError = error => ({
    type: GET_USER_TENANTS_ERROR,
    error
  });

