const aadDomain = `${process.env.REACT_APP_AAD_NAME}.b2clogin.com`;
const redirectUri = `${process.env.REACT_APP_WEB_ADMIN_URL}oidc-response`;
const postLogoutRedirectUri = `${process.env.REACT_APP_WEB_ADMIN_URL}login`;
const signUpSignInPolicyUrl = `https://${process.env.REACT_APP_AAD_NAME}.b2clogin.com/${process.env.REACT_APP_AAD_NAME}.onmicrosoft.com/${process.env.REACT_APP_SIGN_IN_POLICY}`;
const apiScopes1 = `https://${process.env.REACT_APP_AAD_NAME}.onmicrosoft.com/api/ht.catalogapi.read`;
const apiScopes2 = `https://${process.env.REACT_APP_AAD_NAME}.onmicrosoft.com/api/ht.catalogapi.admin`;
const apiScopes3 = `https://${process.env.REACT_APP_AAD_NAME}.onmicrosoft.com/api/ht.eventsapi.admin`;
const apiScopes4 = `https://${process.env.REACT_APP_AAD_NAME}.onmicrosoft.com/api/ht.eventsapi.admin`;
const publicAuthorityURL = `https://${process.env.REACT_APP_AAD_NAME}.b2clogin.com/${process.env.REACT_APP_AAD_NAME}.onmicrosoft.com/`;

export default {
  aad: {
    aadClientId: process.env.REACT_APP_AAD_CLIENT_ID || "350b6ff3-6211-4a9d-b6c5-ed579b593e33",
    aadName: process.env.REACT_APP_AAD_NAME || "helium7dev",
    signInPolicy: process.env.REACT_APP_SIGN_IN_POLICY || "B2C_1A_NEW_SIGNIN",
    aadDomain: aadDomain || "helium7dev.b2clogin.com",
    redirectUri: redirectUri || "http://localhost:3000/oidc-response",
    postLogoutRedirectUri: postLogoutRedirectUri || "http://localhost:3000/login",
    signUpSignInPolicyUrl: signUpSignInPolicyUrl || "https://helium7dev.b2clogin.com/helium7dev.onmicrosoft.com/B2C_1A_NEW_SIGNIN",
    apiScopes: [apiScopes1, apiScopes2, apiScopes3, apiScopes4],
    publicAuthority: publicAuthorityURL,
  },
};
