import React, { useState } from "react";
import PropTypes from "prop-types";
import RSelect from "react-select";
import OutsideClickHandler from "react-outside-click-handler";

function ChipInput(props) {
  const { className, value, onChange, placeholder, isError, size, isDisabled, isClearable } = props;
  const [inputValue, setInputValue] = useState("");

  const color = "#141416";
  const borderColor = isError ? "#d63131" : "#ECEFF2";
  const hoverBorderColor = isError ? "#d63131" : "#b1b5c3";

  const fontSize = size === "lg" ? "14px" : size === "md" ? "13px" : size === "sm" ? "12px" : "10px";
  const controlPadding = size === "lg" ? "3px 0px" : size === "md" ? "1px 0px" : size === "sm" ? "0" : "0";
  const controlMinHeight = size === "lg" ? "38px" : size === "md" ? "34px" : size === "sm" ? "30px" : "26px";
  const indicatorPadding = size === "lg" ? "8px" : size === "md" ? "6px" : size === "sm" ? "4px" : "2px";

  const handleValueChange = (event, isOutsideClick) => {
    const isOptionExist = value?.length > 0 && value.some((option) => option.value === inputValue);
    if ((event?.key === "Enter" || isOutsideClick) && inputValue.trim() !== "" && !isOptionExist) {
      const newOption = { label: inputValue.trim(), value: inputValue.trim() };
      onChange([...value, newOption]);
      setInputValue("");
      event?.preventDefault && event.preventDefault();
    }
  };

  const handleInputChange = (input) => {
    setInputValue(input);
  };

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === "remove-value") {
      onChange(newValue);
    } else if (actionMeta.action === "clear") {
      onChange([]);
      setInputValue("");
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => handleValueChange({}, true)}>
      <RSelect
        value={value}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onKeyDown={handleValueChange}
        onChange={handleChange}
        className={className}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={true}
        closeMenuOnSelect={false}
        isOptionDisabled={() => false}
        noOptionsMessage={() => null}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        styles={{
          control: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isDisabled ? "#f4f5f6" : "#F6F7F9",
            border: isFocused ? `1px solid ${borderColor}` : `1px solid ${borderColor}`,
            borderRadius: "24px",
            fontSize: fontSize,
            fontWeight: "400",
            boxShadow: isFocused ? "" : "",
            padding: controlPadding,
            minHeight: controlMinHeight,
            "&:hover": {
              borderColor: `${hoverBorderColor}`,
            },
          }),
          input: (base) => ({ ...base, color: "#333333" }),
          clearIndicator: (base) => ({
            ...base,
            padding: indicatorPadding,
          }),
          singleValue: (base) => ({
            ...base,
            color: color,
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "12px",
            color: "#777e90",
          }),
        }}
      />
    </OutsideClickHandler>
  );
}

export default ChipInput;

ChipInput.propTypes = {
  className: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  selectedOptions: PropTypes.array,
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
  size: PropTypes.oneOf(["mxs", "sm", "md", "lg"]),
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
};

ChipInput.defaultProps = {
  className: null,
  value: [],
  onChange: () => {},
  selectedOptions: [],
  placeholder: "Enter",
  isError: false,
  size: "lg",
  isDisabled: false,
  isClearable: false,
};
