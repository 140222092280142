import { useState, useEffect } from "react";

export function useMediaQuery() {
  const [width, setWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  return { width, isMD: width > 768, isLG: width > 1199, isMobile: width < 768 };
}
