import React from "react";
import PropTypes from "prop-types";
import SidebarItem from "../SidebarItem";
import classNames from "classnames";
import styles from "./SidebarCategory.module.scss";

function SidebarCategory(props) {
  const { options, handleRedirect, pathname } = props;

  const settingsOption = {
    title: "sidebar.settings",
    icon: "side-config",
    link: "/app/settings",
  };

  return (
    <div className={classNames("flex flex-col justify-between", styles.sidebarCategory, styles.optionsWrapper, "max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-103px)] md:pt-2")}>
      {options?.length > 0 && (
        <div className={classNames(styles.sidebarCategory, styles.optionsMapping)}>
          {options.map((o, i) => {
            if (o.hide) return null;
            return <SidebarItem key={i} item={o} handleRedirect={handleRedirect} pathname={pathname} />;
          })}
        </div>
      )}
      <div className="mt-[3px] lg:mt-7">
        <SidebarItem item={settingsOption} handleRedirect={handleRedirect} pathname={pathname} checkPathActive />
      </div>
    </div>
  );
}

export default SidebarCategory;

SidebarCategory.propTypes = {
  options: PropTypes.array,
  pathname: PropTypes.string,
  handleRedirect: PropTypes.func,
};

SidebarCategory.defaultProps = {
  options: [],
  pathname: "",
  handleRedirect: () => {},
};
