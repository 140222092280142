import React, { useEffect } from "react";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
import { Redirect, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import "styles/bootstrap.scss";
import "styles/app.scss";
import "styles/app-rtl.scss";
import AppLocale from "../lngProvider";
import MainApp from "./App";
import callback from "./callback";
import SignIn from "./SignIn";
import OIDCResponse from "./OIDCResponse";
import TenantList from "./TenantList";
import { useRouter } from "context/RouteContext";
import { useTenantContext } from "context/TenantContext";
import { useAuthContext } from "context/AuthProvider";
import { useSeedContext } from "context/SeedContext";

const RestrictedRoute = ({ component: Component, authUser, router, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!!authUser) {
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: router.location },
          }}
        />
      );
    }}
  />
);

function App() {
  const router = useRouter();
  const { isAuthenticated } = useAuthContext();
  const { locale } = useSeedContext();
  const { tenantId } = useTenantContext();
  const location = router?.location.pathname;
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    if (isAuthenticated && !!tenantId && (location === "" || location === "/" || location === "/signin")) {
      router.push("/app/dashboard");
    } else if (!isAuthenticated && location === "") {
      router.push("/signin");
    } else if (isAuthenticated && !!userId && !tenantId) {
      router.push("/tenants-list");
    }
  }, [tenantId, isAuthenticated, userId, location]);

  const currentAppLocale = AppLocale[locale.locale];
  
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <div className="app-main" dir={currentAppLocale.locale === "ar-SA" ? "rtl" : "ltr"}>
          <Switch>
            <Route path={`/oidc-response`} component={OIDCResponse} />
            <RestrictedRoute path={`/app`} authUser={userId} component={MainApp} router={router} />
            <Route path="/signin" component={SignIn} />
            {/* <Route path="/signup" component={SignUp} /> */}
            <Route path="/callback" component={callback} />
            <Route path="/tenants-list" component={TenantList} />
            <Route component={SignIn} />
          </Switch>
        </div>
      </IntlProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
