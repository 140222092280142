import React from "react";
import PropTypes from "prop-types";
import Input from "components/Common/Input";
import classNames from "classnames";

const SearchInput = (props) => {
  const { value, onChange, isDisabled, className } = props;

  return (
    <div className={classNames("search-bar right-side-icon bg-transparent", className)}>
      <div className="form-group">
        <Input placeholder="placeholder.search" value={value} onChange={onChange} isDisabled={isDisabled} startIconName="search" isSearchIcon inputContainerClassName="gap-0" />
      </div>
    </div>
  );
};
export default SearchInput;

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

SearchInput.defaultProps = {
  value: "",
  onChange: () => {},
  isDisabled: false,
  className: "",
};
