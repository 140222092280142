import axios from 'axios';
import config from '../../config/config';
import { errorHelper, responseHelper, axiosConfig } from './httpHelper';
import { EVENTS, IDENTITY } from './constants';

const baseUrl = `${config.eventsAPI}${EVENTS}/`;

export default function axiosHttpClient(baseURL = baseUrl) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const axiosClient = axios.create({
    baseURL: baseURL,
    headers,
  });

  axiosClient.interceptors.request.use(axiosConfig);
  axiosClient.interceptors.response.use(responseHelper, errorHelper);

  return axiosClient;
}
