import { eventsClient } from "./http";

class TenantsServices {
  createTenant = async (tenantDetails) => await eventsClient.post(`api/tenants`, tenantDetails);

  addTenantLogo = async (tenantId, imageData) => {
    if (tenantId) {
      return await eventsClient.post(`api/tenants/${tenantId}/logo`, imageData);
    }
    return null;
  };

  getTenantById = async (tenantId) => await eventsClient.get(`api/tenants/${tenantId}`);

  changeTenantDetails = async (tenantId, data) => {
    if (tenantId) {
      return await eventsClient.patch(`api/tenants/${tenantId}`, data);
    }
  };

  deleteTenantLogo = async (tenantId) => await eventsClient.delete(`api/tenants/${tenantId}/logo`);

  addAnalyticsSettings = async (tenantId, data) => {
    if (tenantId) {
      return await eventsClient.post(`api/tenants/${tenantId}/analytics-settings`, data);
    }
    return null;
  };

  addEmailSettings = async (tenantId, data) => {
    if (tenantId) {
      return await eventsClient.post(`api/tenants/${tenantId}/email-settings`, data);
    }
    return null;
  };

  getTenantSettings = async (tenantId) => {
    if (tenantId) {
      return await eventsClient.get(`api/tenants/${tenantId}/settings`);
    }
    return null;
  };

  getEmailTemplates = async (tenantId) => {
    if (tenantId) {
      return await eventsClient.get(`api/tenants/${tenantId}/email-templates`);
    }
    return null;
  };

  updateEmailTemplateSettings = async (tenantId, templateKey, data) => {
    if (tenantId && templateKey && data) {
      return await eventsClient.post(`api/tenants/${tenantId}/email-template-settings/${templateKey}`, data);
    }
    return null;
  };

  getTenantBillingInfo = async (tenantId) => {
    if (tenantId) {
      return await eventsClient.get(`api/tenants/${tenantId}/billing-info`);
    }
    return null;
  };

  addSiteAdmin = async (tenantId, data) => {
    if (tenantId) {
      return await eventsClient.post(`api/tenants/${tenantId}/site-admins`, data);
    }
    return null;
  };

  getSiteAdmins = async (tenantId, searchOptions) => {
    if (tenantId) {
      const params = {
        skip: searchOptions.skip,
        take: searchOptions.take,
      };

      searchOptions.searchTerm && (params.searchTerm = searchOptions.searchTerm);

      return await eventsClient.get(`api/tenants/${tenantId}/site-admins`, { params });
    }
    return null;
  };
}

const instance = new TenantsServices();

export default instance;
