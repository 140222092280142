import { eventsClient } from "./http";

export const getUserTenants = async (userId, { skip, take, searchTerm }) => {
  if (!userId) return null;

  const params = {
    skip: skip || 0,
    take: take || 100,
    searchTerm: searchTerm || "",
  };

  return await eventsClient.get(`/api/users/${userId}/tenants`, { params });
};
