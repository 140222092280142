import React, { createContext, useEffect, useState, useMemo, useContext } from "react";

export const RouteContext = createContext({});

export const useRouter = () => useContext(RouteContext);

function RouteProvider(props) {
  const { history } = props;
  const [location, setLocation] = useState(history?.location);

  useEffect(() => {
    const unlisten = history?.listen((newLocation) => {
      setLocation((prevLocation) => {
        if (prevLocation !== newLocation) {
          return newLocation;
        }
        return prevLocation;
      });
    });

    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, [history]);

  const push = (path) => {
    if (history && path) {
      history.push(path);
    }
  };

  const replace = (path) => {
    if (history && path) {
      history.replace(path);
    }
  };

  const value = useMemo(
    () => ({
      push,
      location,
      replace,
    }),
    [location]
  );

  return <RouteContext.Provider value={value}>{props.children}</RouteContext.Provider>;
}

export default RouteProvider;
