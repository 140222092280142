import React, { useState, useEffect, useContext, createContext, useMemo } from "react";
import AuthServices, { h7Application } from "utils-auth/authService";
import { useRouter } from "./RouteContext";
import { tokenRequest } from "utils-auth/AuthConfigAAD";

export const AuthContext = createContext({});

export const useAuthContext = () => useContext(AuthContext);

function AuthProvider(props) {
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [userId, setUserId] = useState("");

  async function handleRefetch() {
    const response = h7Application.getAllAccounts();
    if (response?.length > 0 && response?.[0]?.homeAccountId) {
      const homeAccountId = response[0].homeAccountId;
      const tokenResponse = await AuthServices.getTokenRedirect(tokenRequest, homeAccountId);
      if (tokenResponse?.accessToken) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const resp = AuthServices.handleAuthentication();
    if (resp === "noAccessToken") {
      router.push("/signin");
    } else if (resp === "notValidToken") {
      handleNotValidToken();
    } else if (resp === "Authenticated") {
      const decodedToken = AuthServices.getDecodedAccessToken();
      if (decodedToken) {
        if (decodedToken.userRoles && decodedToken.userRoles.length) {
          setIsAuthenticated(true);
          setRole(decodedToken.userRoles);
          setName(decodedToken.name);
          setUserId(decodedToken.sub ? decodedToken.sub : null);
        } else {
          handleNotValidToken();
        }
      }
    }
  }, []);

  async function handleNotValidToken() {
    const isTokenRefetchSuccess = await handleRefetch();
    if (!isTokenRefetchSuccess) {
      AuthServices.logOut();
    }
  }

  // check by interval and notify 5 min before token expire
  useEffect(() => {
    const interval = setInterval(async () => {
      const isAboutToExpire = AuthServices.isAboutToExpire();
      if (isAboutToExpire) {
        handleNotValidToken();
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const resp = AuthServices.handleAuthentication();
      if (resp === "noAccessToken") {
        router.push("/signin");
      } else if (resp === "notValidToken") {
        handleNotValidToken();
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const values = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      name,
      setName,
      role,
      setRole,
      tenantId,
      setTenantId,
      userId,
      setUserId,
      isSuperAdmin: role?.length > 0 && role?.[0] === "SuperAdmin",
      isOrgAdmin: role?.length > 0 && role?.[0] === "OrgAdmin",
    }),
    [isAuthenticated, name, role, tenantId, userId]
  );

  return <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>;
}

export default AuthProvider;
