import axiosHttpClient from "./axiosHttpClient";
import config from "../../config/config";
import axiosHttpClientWithoutError from "./axiosHttpClientWithoutError";

export const eventsClient = axiosHttpClient(config.eventsAPI);
export const ordersClient = axiosHttpClient(config.ordersApiUrl);
export const storeFrontClient = axiosHttpClient(config.storeFrontApiUrl);
export const eventsClientWithoutError = axiosHttpClientWithoutError(config.eventsAPI);
export const catalogApiClientWithoutError = axiosHttpClientWithoutError(config.catalogApiUrl);
export const idSrvClient = axiosHttpClient(config.idSrvUrl);
export const webhooksClient = axiosHttpClient(config.webhooksAPI);
