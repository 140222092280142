import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import MuiPhoneInput from "material-ui-phone-number";

function PhoneInput(props) {
  const { value, onChange, isError, isDisabled } = props;
  const defaultCountryCode = "us";

  return (
    <MuiPhoneInput
      defaultCountry={defaultCountryCode}
      onlyCountries={["us"]}
      value={value}
      onChange={onChange}
      className={classNames("input-phone-number", {
        "input-phone-number-error": isError,
      })}
      disabled={isDisabled}
    />
  );
}

export default PhoneInput;

PhoneInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

PhoneInput.defaultProps = {
  value: "",
  onChange: () => {},
  isError: false,
  isDisabled: false,
};
