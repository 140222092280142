import { eventsClient } from "./http";

export const getAssociateTypes = async (tenantId) => {
  if (!tenantId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/associate-types`);
};

export const addAssociate = async (tenantId, data) => {
  if (!tenantId || !data) return null;

  return await eventsClient.post(`/api/tenants/${tenantId}/event-associates`, data);
};

export const updateAssociate = async (tenantId, associateId, data) => {
  if (!tenantId || !associateId || !data) return null;

  return await eventsClient.patch(`/api/tenants/${tenantId}/event-associates/${associateId}`, data);
};

export const getAssociateList = async (tenantId, searchOptions) => {
  if (!tenantId) return null;

  const params = {
    skip: searchOptions.skip,
    take: searchOptions.take,
  };

  searchOptions.skipPaging && (params.skipPaging = searchOptions.skipPaging);
  searchOptions.searchTerm && (params.searchTerm = searchOptions.searchTerm);
  searchOptions.type && (params.type = searchOptions.type);

  return await eventsClient.get(`/api/tenants/${tenantId}/event-associates`, { params });
};

export const getAssociateDetails = async (tenantId, associateId) => {
  if (!tenantId || !associateId) return null;

  return await eventsClient.get(`/api/tenants/${tenantId}/event-associates/${associateId}`);
};

export const removePhotoOfAssociate = async (tenantId, associateId, mediaId) => {
  if (!tenantId || !associateId || !mediaId) return null;

  return await eventsClient.delete(`/api/tenants/${tenantId}/event-associates/${associateId}/photo-mappings/${mediaId}`);
};
