/* eslint-disable wrap-iife */
import moment from "moment";

export const createObject = (key, value) => {
  const obj = {};
  const parts = key.split(".");
  if (parts.length == 1) {
    obj[parts[0]] = value;
  } else if (parts.length > 1) {
    // concat all but the first part of the key
    const remainingParts = parts.slice(1, parts.length).join(".");
    obj[parts[0]] = createObject(remainingParts, value);
  }
  return obj;
};

export const parseBoolean = (string) => {
  let bool;
  bool = (function () {
    switch (false) {
      case string.toLowerCase() !== "true":
        return true;
      case string.toLowerCase() !== "false":
        return false;
    }
  })();
  if (typeof bool === "boolean") {
    return bool;
  }
  return void 0;
};

export const convertTimezone = (timezone, time, timeFormat) => {
  let date = "";
  if (timezone && time && timeFormat) {
    date = moment.tz(time.format(timeFormat), timezone.baseUtcOffset).utc().format();
  }
  return date;
};

export const getRandomCode = (length_) => {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZ".split("");
  if (typeof length_ !== "number") {
    length_ = Math.floor(Math.random() * chars.length_);
  }
  let str = "";
  for (let i = 0; i < length_; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
};

export const copyValue = (value) => {
  let dummy = document.createElement("TEXTAREA");
  document.body.appendChild(dummy);
  dummy.innerHTML = value;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

export const checkIfApiResponseOk = (response) => response.status == 200 || response.statusText == "OK";

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export function validURL(url) {
  const regex = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{0,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return !url || regex.test(url);
}

export const isEmailValid = (email) => {
  const emailMatchRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailMatchRegEx.test(email);
};

export const isPasswordValid = (password) => {
  const passwordMatchRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
  return passwordMatchRegEx.test(password);
};

export const isValidPhoneNum = (value) => {
  var regex = /^\d{7,15}$/;
  if (value && regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

export function humanizeCount(number) {
  if (typeof number === "number") {
    let num = 0;
    if (number > 1000000) {
      num = number / 1000000;
      return `${Math.floor(num).toLocaleString()}M+`;
    } else if (number > 1000) {
      num = number / 1000;
      return `${Math.floor(num).toLocaleString()}K+`;
    }
    return Math.floor(number).toLocaleString();
  }
  return null;
}

export function humanizeCountWithDecimal(number) {
  if (typeof number === "number") {
    let num = 0;
    if (number > 1000000) {
      num = number / 1000000;
      return `${num.toFixed(2).toLocaleString()}M+`; // 2 decimal places for millions
    } else if (number > 1000) {
      num = number / 1000;
      return `${num.toFixed(2).toLocaleString()}K+`; // 2 decimal places for thousands
    }
    return number.toFixed(2).toLocaleString(); // 2 decimal places for small numbers
  }
  return null;
}

export const paramsSerializer = (params) => {
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return params[key].map((value) => `${key}=${value}`).join("&");
      }
      return `${key}=${params[key]}`;
    })
    .join("&");
};

// Regular expressions for validation
const REGEX_STRING = /^[a-zA-Z -]+$/;
const REGEX_EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-z0-9](?:[a-z0-9-]*[a-z0-9](?:\.[a-z0-9-]*[a-z0-9])?)$/i;
const REGEX_DIGITS = /^\d+$/;

export function validateField(fieldName, fieldLabel, fieldValue, options = "") {
  const formattedFieldName = fieldLabel || fieldName;

  const validateRequired = (value) => !value || value.toString().trim() === "";
  const validateString = (value) => !REGEX_STRING.test(value);
  const validateEmail = (value) => !REGEX_EMAIL.test(value);
  const validateMin = (value, option) => (Array.isArray(value) ? value.length < option : value.toString().trim().length < option);
  const validateMax = (value, option) => value.toString().trim().length > option;
  const validateNumber = (value) => !REGEX_DIGITS.test(value);

  const optionList = options.split("|");

  let validationError = null;

  optionList.find((optionString) => {
    const [optionType, optionValue] = optionString.split(":");
    switch (optionType) {
      case "required":
        if (validateRequired(fieldValue)) {
          validationError = `${formattedFieldName} is required.`;
          return validationError;
        }
        break;
      case "string":
        if (validateString(fieldValue)) {
          validationError = `Please enter valid ${formattedFieldName}.`;
          return validationError;
        }
        break;
      case "email":
        if (validateEmail(fieldValue)) {
          validationError = "Please enter a valid email address.";
          return validationError;
        }
        break;
      case "min":
        if (validateMin(fieldValue, parseInt(optionValue, 10))) {
          validationError = Array.isArray(fieldValue) ? `At least ${optionValue} item must be selected.` : `${formattedFieldName} must contain at least ${optionValue} character(s).`;
          return validationError;
        }
        break;
      case "max":
        if (validateMax(fieldValue, parseInt(optionValue, 10))) {
          validationError = `${formattedFieldName} should not be ${optionValue} characters long.`;
          return validationError;
        }
        break;
      case "number":
        if (validateNumber(fieldValue)) {
          validationError = `${formattedFieldName} should contain numbers only.`;
          return validationError;
        }
        break;
      default:
        break;
    }
  });

  return validationError;
}

export const formatCamelCase = (string) => {
  return string
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export function checkFifteenMinuteInterval(showTimes) {
  return showTimes?.some((item, index, array) => {
    const nextTime = array[index + 1];
    if (nextTime) {
      const timeDifference = moment(nextTime.time, "hh:mm A").diff(moment(item.time, "hh:mm A"), "minutes");
      return timeDifference === 15;
    }
    return false;
  });
}

export const getMediaDimension = (url) => {
  const img = new Image();
  img.src = url;
  return { imgHeight: img.naturalHeight, imgWidth: img.naturalWidth, naturalAspectRatio: img.naturalWidth / img.naturalHeight };
};

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getAddressString = (address) => {
  const parts = [];
  if (address.addressLine1) parts.push(address.addressLine1);
  if (address.addressLine2) parts.push(address.addressLine2);
  if (address.cityLocality || address.stateProvince || address.postalCode) {
    let cityStatePostal = "";
    if (address.cityLocality) cityStatePostal += address.cityLocality;
    if (address.stateProvince) {
      if (cityStatePostal) cityStatePostal += ", ";
      cityStatePostal += address.stateProvince;
    }
    if (address.postalCode) {
      if (cityStatePostal) cityStatePostal += " ";
      cityStatePostal += address.postalCode;
    }
    parts.push(cityStatePostal);
  }
  if (address.countryCode) parts.push(address.countryCode);
  return parts.join(", ");
};

export function fileSizeCheck(size, limit) {
  if (size > limit) {
    return false;
  }
  return true;
}

export function sanitizeUrlProtocol(url) {
  return url?.replace(/https?:\/\//gi, "");
}
