import React from "react";
import PropTypes from "prop-types";
import styles from "./Avatar.module.scss";
import classNames from "classnames";

function Avatar(props) {
  const { src, alt, style, children, className } = props;
  return (
    <div className={classNames(styles.avatar, className)} style={style}>
      {src ? <img src={src} alt={alt} className={styles.image} /> : children}
    </div>
  );
}

export default Avatar;

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  src: "",
  alt: "",
  style: {},
  children: null,
  className: "",
};
