import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Label from "../Label";
import styles from "./Input.module.scss";
import Icons from "../Icons";
import PhoneInput from "../PhoneInput";
import Select from "../Select";
import { Simplify } from "conditional-render-simplify";
import CurrencyInput from "react-currency-input-field";
import IntlMessages from "util/IntlMessages";
import ChipInput from "../ChipInput";
import { injectIntl } from "react-intl";

function Input(props) {
  const {
    label,
    isRequired,
    placeholder,
    value,
    onChange,
    isError,
    errorMessage,
    showErrorMessage,
    showHelperText,
    helperText,
    inputType,
    startIconName,
    endIconName,
    isTextArea,
    options,
    getOptionLabel,
    getOptionValue,
    isSearchable,
    isMulti,
    isClearable,
    selectSize,
    isDisabled,
    filterOption,
    size,
    rows,
    maxTextLength,
    decimalScale,
    isDisableGroupSeparators,
    onBlur,
    isOptionDisabled,
    startComponent,
    endComponent,
    componentSize,
    isPositiveValue,
    isTargetValue,
    onInputChange,
    onKeyDown,
    isLoading,
    menuPortalTarget,
    menuPlacement,
    className,
    mainClassName,
    isPaginationSelect,
    isSearchIcon,
    inputContainerClassName,
    intl,
    isInputFocus,
    isDiscountCode,
    showBorder,
    prefix,
    inputMaxLength,
  } = props;
  const inputRef = useRef(null);
  const [isPasswordType, setIsPasswordType] = useState(true);

  const isAmountInput = inputType === "amount";
  const isSelectInput = inputType === "select";
  const isPhoneInput = inputType === "phone";
  const isNumberInput = inputType === "number";
  const isPasswordInput = inputType === "password";
  const isChipInput = inputType === "chip";
  const isLanguagePlaceholder = placeholder.includes("placeholder.");

  useEffect(() => {
    if (isInputFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputFocus]);

  function handleChange(e) {
    let value = e.target.value;
    if (isDiscountCode) {
      // Remove spaces, convert to uppercase, allow hyphen (-) and exclamation (!)
      value = value.toUpperCase().replace(/[^A-Z0-9-!]/g, "");
      if (value.length > 50) {
        value = value.slice(0, 50);
      }
    }

    if (inputType === "string" || isPasswordInput) {
      onChange(isTargetValue ? e : value);
    } else if (isNumberInput) {
      if (isPositiveValue) {
        if (isNaN(+value) || +value < 0) {
          return;
        }
        if (value === "") {
          onChange("");
          return;
        }
        const parsedValue = +value;
        const updatedValue = isFinite(parsedValue) ? parsedValue : 0;
        onChange(updatedValue);
      } else {
        if (isNaN(+value)) {
          return;
        }
        onChange(value);
      }
    } else if (isAmountInput) {
      onChange(isTargetValue ? e : value);
    }
  }

  const inputClassName = classNames([
    styles.input,
    { [styles.inputBorder]: showBorder },
    styles[size],
    className,
    {
      [styles.startComponent]: !!startComponent,
      [styles.endComponent]: !!endComponent,
      [styles.startComponent[componentSize]]: !!startComponent,
      [styles.endComponent[componentSize]]: !!endComponent,
      [styles.passwordComponent]: isPasswordInput,
    },
    {
      [styles.isError]: isError,
      [styles.startIcon]: !isSearchIcon && !!startIconName,
      [styles.searchIcon]: !!isSearchIcon,
      [styles.endIcon]: !!endIconName || isPasswordInput,
      [styles.textarea]: !!isTextArea,
    },
  ]);

  return (
    <div className={classNames(styles.mainContainer, mainClassName)}>
      {label && <Label text={label} isRequired={isRequired} type="input" className={classNames([styles.label, { [styles.labelError]: isError }])} />}
      <div className={classNames(styles.inputContainer, inputContainerClassName)}>
        <Simplify
          conditions={{
            select: isSelectInput,
            textarea: isTextArea,
            phoneNumber: isPhoneInput,
            amount: isAmountInput,
            password: isPasswordInput,
            chip: isChipInput,
            input: true,
          }}
          select={
            <Select
              options={options}
              value={value}
              onChange={onChange}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              isSearchable={isSearchable}
              isClearable={isClearable}
              placeholder={placeholder}
              isError={isError}
              size={selectSize}
              isDisabled={isDisabled}
              isMulti={isMulti}
              filterOption={filterOption}
              dropdownIcon={endIconName}
              isOptionDisabled={isOptionDisabled}
              onInputChange={onInputChange}
              isLoading={isLoading}
              menuPortalTarget={menuPortalTarget}
              menuPlacement={menuPlacement}
              isPaginationSelect={isPaginationSelect}
              className={className}
              showBorder={showBorder}
            />
          }
          textarea={
            <textarea
              ref={inputRef}
              rows={rows}
              className={inputClassName}
              value={value}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              placeholder={isLanguagePlaceholder ? intl.formatMessage({ id: placeholder }) : placeholder}
              disabled={isDisabled}
              maxLength={maxTextLength}
            />
          }
          phoneNumber={<PhoneInput value={value} onChange={onChange} isError={isError} isDisabled={isDisabled} />}
          amount={
            <CurrencyInput
              value={value}
              onValueChange={(v) => {
                onChange(v);
              }}
              decimalScale={decimalScale}
              className={inputClassName}
              placeholder={isLanguagePlaceholder ? intl.formatMessage({ id: placeholder }) : placeholder}
              disabled={isDisabled}
              disableGroupSeparators={isDisableGroupSeparators}
              prefix={prefix}
            />
          }
          password={
            <input
              ref={inputRef}
              className={inputClassName}
              type={isPasswordType ? "password" : "string"}
              value={value}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              placeholder={isLanguagePlaceholder ? intl.formatMessage({ id: placeholder }) : placeholder}
              disabled={isDisabled}
              onBlur={onBlur}
            />
          }
          chip={
            <ChipInput
              value={value}
              onChange={onChange}
              placeholder={isLanguagePlaceholder ? intl.formatMessage({ id: placeholder }) : placeholder}
              isError={isError}
              size={selectSize}
              isDisabled={isDisabled}
              isClearable={isClearable}
            />
          }
          input={
            <input
              ref={inputRef}
              className={inputClassName}
              value={value}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              placeholder={isLanguagePlaceholder ? intl.formatMessage({ id: placeholder }) : placeholder}
              disabled={isDisabled}
              onBlur={onBlur}
              maxLength={inputMaxLength}
            />
          }
        />
        {isPasswordInput && (
          <Icons
            type={isPasswordType ? "eyeOff" : "eye"}
            size="sm"
            className={classNames([styles.inputIcon, styles.endIcon, styles[size], styles.passwordIcon])}
            onClick={() => setIsPasswordType((s) => !s)}
          />
        )}
        {!isSelectInput && startComponent && <div className={classNames([styles.inputIcon, styles.startIcon, styles[size]])}>{startComponent}</div>}
        {!isSelectInput && startIconName && <Icons type={startIconName} size="sm" className={classNames([styles.inputIcon, styles.startIcon, styles[size]], { "opacity-20": isDisabled })} />}
        {!isSelectInput && endIconName && <Icons type={endIconName} size="sm" className={classNames([styles.inputIcon, styles.endIcon, styles[size]], { "opacity-20": isDisabled })} />}
        {!isSelectInput && endComponent && <div className={classNames([styles.inputIcon, styles.endIcon, styles[size]])}>{endComponent}</div>}

        {showHelperText && helperText && <Label type="helperText" text={helperText} className="mt-1" />}
        {isError &&
          showErrorMessage &&
          (errorMessage ? <Label text={errorMessage} type="input-error" className="mt-1" /> : <Label text={<IntlMessages id="common.error.required" />} type="input-error" className="mt-1" />)}
      </div>
    </div>
  );
}

export default injectIntl(Input);

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  errorMessage: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  inputType: PropTypes.oneOf(["string", "number", "amount", "select", "phone", "password"]),
  startIconName: PropTypes.string,
  endIconName: PropTypes.string,
  isTextArea: PropTypes.bool,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  selectSize: PropTypes.oneOf(["mxs", "sm", "md", "lg"]),
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  filterOption: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  rows: PropTypes.number,
  maxTextLength: PropTypes.number,
  decimalScale: PropTypes.number,
  isDisableGroupSeparators: PropTypes.bool,
  onBlur: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  startComponent: PropTypes.any,
  endComponent: PropTypes.any,
  componentSize: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  isPositiveValue: PropTypes.bool,
  isTargetValue: PropTypes.bool,
  onInputChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  isLoading: PropTypes.bool,
  menuPortalTarget: PropTypes.any,
  menuPlacement: PropTypes.string,
  className: PropTypes.string,
  mainClassName: PropTypes.string,
  isPaginationSelect: PropTypes.bool,
  isSearchIcon: PropTypes.bool,
  inputContainerClassName: PropTypes.string,
  isInputFocus: PropTypes.bool,
  isDiscountCode: PropTypes.bool,
  showBorder: PropTypes.bool,
  prefix: PropTypes.string,
  inputMaxLength: PropTypes.string,
};

Input.defaultProps = {
  label: "",
  placeholder: "",
  isRequired: false,
  value: "",
  onChange: () => {},
  isError: false,
  showErrorMessage: true,
  errorMessage: "",
  showHelperText: false,
  helperText: "",
  inputType: "string",
  startIconName: "",
  endIconName: "",
  isTextArea: false,
  options: [],
  getOptionLabel: () => {},
  getOptionValue: () => {},
  isSearchable: false,
  selectSize: "lg",
  isDisabled: false,
  isMulti: false,
  filterOption: undefined,
  size: "lg",
  rows: 4,
  maxTextLength: 2048,
  decimalScale: undefined,
  isDisableGroupSeparators: false,
  onBlur: () => {},
  isOptionDisabled: undefined,
  startComponent: null,
  endComponent: null,
  componentSize: "lg",
  isPositiveValue: true,
  isTargetValue: false,
  onInputChange: () => {},
  onKeyDown: () => {},
  isLoading: false,
  menuPortalTarget: null,
  menuPlacement: "auto",
  className: "",
  mainClassName: "",
  isPaginationSelect: false,
  isSearchIcon: false,
  inputContainerClassName: "",
  isInputFocus: false,
  isDiscountCode: false,
  showBorder: true,
  prefix: "",
  inputMaxLength: 2048,
};
