import React, { createContext, useEffect, useState, useMemo, useContext } from "react";
import { useQuery } from "react-query";
import { getEventDetails, getEventTicketTypes, getTicketTypeDetails } from "services-manager/eventsManager";
import Humanize from "humanize-plus";
import { useTenantContext } from "./TenantContext";
import { useRouter } from "./RouteContext";

export const EventContext = createContext({});

export const useEventContext = () => useContext(EventContext);

function EventProvider(props) {
  const router = useRouter();
  const tenantDetails = useTenantContext();
  const { tenantId } = tenantDetails;

  const [eventId, setEventId] = useState("");
  const [ticketTypeId, setTicketTypeId] = useState("");
  const [eventData, setEventData] = useState(null);
  const [isTicketTypeArchived, setIsticketTypeArchived] = useState(false);

  useEffect(() => {
    if (router && router.location.pathname.includes("/events/")) {
      const pathArr = router.location.pathname.split("/");
      const eventIndex = pathArr.indexOf("events");
      const findEventId = pathArr[eventIndex + 1];
      if (findEventId.length === 24 && findEventId !== eventId) {
        setEventId(findEventId);
      }
    }
    if (router && router.location.pathname.includes("/ticket-types/")) {
      const pathArr = router.location.pathname.split("/");
      const ticketTypeIndex = pathArr.indexOf("ticket-types");
      const findTicketTypeId = pathArr[ticketTypeIndex + 1];
      if (findTicketTypeId.length === 24 && findTicketTypeId !== ticketTypeId) {
        setTicketTypeId(findTicketTypeId);
      }
    }
  }, [router]);

  const {
    isLoading: isEventDetailsLoading,
    isFetched: isEventDetailsFetched,
    data: eventDetails,
    refetch: refetchEventDetails,
  } = useQuery([tenantId, eventId, `/api/tenants/${tenantId}/events/${eventId}`], getEventDetails, { refetchOnWindowFocus: false, enabled: !!tenantId && !!eventId });

  const {
    isLoading: isTicketTypesLoading,
    data: ticketTypesData,
    isFetched: isTicketTypesFetched,
    refetch: refetchTicketTypes,
  } = useQuery([tenantId, eventId, { searchTerm: "", archived: isTicketTypeArchived, key: `/api/tenants/${tenantId}/events/${eventId}/ticket-types` }], getEventTicketTypes, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && !!eventId,
  });

  const {
    isLoading: isTicketTypeDetailsLoading,
    isFetched: isTicketTypeDetailsFetched,
    data: ticketTypeDetails,
    refetch: refetchTicketTypeDetails,
  } = useQuery([tenantId, eventId, ticketTypeId, { key: `/api/tenants/${tenantId}/events/${eventId}/ticket-types/${ticketTypeId}` }], getTicketTypeDetails, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && !!eventId && !!ticketTypeId,
  });

  useEffect(() => {
    if (isTicketTypesFetched && ticketTypeDetails?.isArchived) {
      setIsticketTypeArchived(true);
    } else {
      setIsticketTypeArchived(false);
    }
  }, [isTicketTypeDetailsLoading, isTicketTypesFetched, ticketTypeDetails]);

  useEffect(() => {
    if (isEventDetailsFetched && eventDetails) {
      setEventData(eventDetails);
    }
  }, [isEventDetailsLoading, isEventDetailsFetched, eventDetails]);

  const value = useMemo(
    () => ({
      tenantId: tenantId,
      eventId,
      ticketTypeId,
      tenantDetails: tenantDetails,
      eventData: eventData,
      refetchEventDetails: refetchEventDetails,
      isTicketTypesLoading: isTicketTypesLoading,
      isTicketTypesFetched: isTicketTypesFetched,
      ticketTypesData: ticketTypesData,
      refetchTicketTypes: refetchTicketTypes,
      isTicketTypeDetailsLoading: isTicketTypeDetailsLoading,
      isTicketTypeDetailsFetched: isTicketTypeDetailsFetched,
      ticketTypeDetails: ticketTypeDetails,
      refetchTicketTypeDetails: refetchTicketTypeDetails,
      ticketTypeName: Humanize.truncate(ticketTypeDetails?.title || "", 25, "..."),
      fullTicketTypeName: ticketTypeDetails?.title,
      eventName: Humanize.truncate(eventData?.title || "", 25, "..."),
      fullEventName: eventData?.title,
    }),
    [router, tenantId, tenantDetails, eventId, eventData, isTicketTypesLoading, isTicketTypesFetched, ticketTypesData, isTicketTypeDetailsLoading, isTicketTypeDetailsFetched, ticketTypeDetails]
  );

  return <EventContext.Provider value={value}>{props.children}</EventContext.Provider>;
}

export default EventProvider;
