import { eventsClient, idSrvClient } from "./http";

export const getBocaCommandTypes = async () => {
  return await eventsClient.get(`/api/boca-command-types`);
};

export const getBocaRotateTypes = async () => {
  return await eventsClient.get(`/api/boca-rotate-types`);
};

export const getBocaFontTypes = async () => {
  return await eventsClient.get(`/api/boca-font-types`);
};

export const getDataFields = async () => {
  return await eventsClient.get(`/api/dynamic-fields`);
};

export const getUserClaims = async (userId) => {
  if (userId) {
    return await eventsClient.get(`/api/users/${userId}/profile`);
  }
  return null;
};

export const getUserInfo = async () => {
  return await idSrvClient.get(`/userinfo`);
};

export const getQzCertificate = async (tenantId) => {
  return await eventsClient.get(`api/tenants/${tenantId}/qz-certificate`);
};
