import { snackActions } from "components/Notification";

export function errorHelper(error) {
  if (error?.config?.returnError) {
    return error.response || error;
  }

  if (error?.config?.hideErrorMsg) {
    return null;
  }

  if (!error?.response?.data?.error) {
    snackActions.error(error?.response?.data?.errorMessage || error?.response?.data?.description || error?.response?.data?.message || "Unexpected error occurred", "Error", 8000);
    return null;
  }

  const errorCode = error.response.data.code;
  const customErrorMsg = error.config.customErrorMsg;
  // snackActions.error(error?.response.error.errorCodes ? response.error.errorCodes[0].description: response.error.errorMessage);
  const msg =
    error.response.error?.errorCodes?.length > 0
      ? error.response.error.errorCodes[0].description
      : error.response.description
      ? error.response.description
      : error.response.error?.errorMessage || "Unexpected error occurred";

  if (customErrorMsg) {
    const codes = customErrorMsg.map((m) => m.originalMsgCode);
    if (codes.includes(errorCode)) {
      const data = customErrorMsg.find((m) => m.originalMsgCode === errorCode);
      const message = data.customMsg || msg;
      const duration = data.duration || 8000;
      snackActions.error(message, "Error", duration);
      return null;
    }
  }

  if (error.response.data.errors) {
    Object.keys(error.response.data.errors).map((key) => {
      snackActions.error(error.response.data.errors[key].toString(), "Error", 8000);
    });
  } else {
    snackActions.error(msg, "Error", 8000);
  }
  return null;
}

export function responseHelper(response) {
  if (response.status === 200 || response.status === 201) {
    return response.data;
  }
  return null;
}

export function axiosConfig(config) {
  const token = localStorage.getItem("access_token");
  const locale = localStorage.getItem("TB_ADMIN_LANGUAGE");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers["Accept-Language"] = locale || "en";
  return config;
}
