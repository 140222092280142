import React from "react";
import PropTypes from "prop-types";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import Icons from "components/Common/Icons";
import classNames from "classnames";

function ActionsMenu(props) {
  const { children, actions, align, direction, menuButtonClassName, className, menuClassName } = props;

  return (
    <Menu
      align={align}
      direction={direction}
      portal
      menuButton={<MenuButton className={classNames("react-menu-menubutton", menuButtonClassName)}>{children}</MenuButton>}
      position="anchor"
      className={className}
      menuClassName={menuClassName}
    >
      {actions?.map((item, index) => {
        if (item?.hide) return;
        return (
          <MenuItem disabled={item?.disabled} onClick={item?.onClick} key={`menu-item-${index}`} className={classNames("flex gap-2 items-center", item?.className)}>
            {item?.icon && (
              <Icons
                type={item?.icon}
                size="xs"
                className={classNames({
                  [item?.iconClassName]: !!item?.iconClassName,
                  // "text-blue": !item?.iconClassName
                })}
              />
            )}
            {item?.title}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

export default ActionsMenu;

ActionsMenu.propTypes = {
  children: PropTypes.any,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      hide: PropTypes.bool,
    })
  ),
  align: PropTypes.oneOf(["start", "center", "end"]),
  direction: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  menuButtonClassName: PropTypes.string,
  className: PropTypes.string,
  menuClassName: PropTypes.string,
};

ActionsMenu.defaultProps = {
  children: null,
  actions: [],
  align: "start",
  direction: "bottom",
  menuButtonClassName: "",
  className: "",
  menuClassName: "",
};
