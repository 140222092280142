import { action, makeAutoObservable } from "mobx";

class SidebarNavStoreClass {
  navCollapsed = false;

  constructor() {
    makeAutoObservable(this, {
      toggleCollapsedNav: action.bound,
    });
  }

  toggleCollapsedNav = () => (this.navCollapsed = !this.navCollapsed);
}

const sidebarNavStore = new SidebarNavStoreClass();

export default sidebarNavStore;
