import React, { useEffect } from "react";
import AuthServices, { h7Application } from "utils-auth/authService";
import { tokenRequest } from "utils-auth/AuthConfigAAD";
import { useRouter } from "context/RouteContext";
import Loader from "components/Loader";
import { useAuthContext } from "context/AuthProvider";

const OIDCResponse = () => {
  const router = useRouter();
  const { setIsAuthenticated, setRole, setName, setUserId } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("access_token");

      if (!token) {
        try {
          const response = await h7Application.handleRedirectPromise();
          if (response != null) {
            const res = await AuthServices.getTokenRedirect(tokenRequest, response.account.homeAccountId);
            if (res) {
              localStorage.setItem("access_token", res.accessToken);
              localStorage.setItem("user_id", res.idTokenClaims.sub);

              setIsAuthenticated(true);
              setRole(res.idTokenClaims.userRoles);
              setName(res.idTokenClaims.name);
              setUserId(res.idTokenClaims.sub);

              router.push("organizations");
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        router.push("/tenants-list");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="h-[100%] flex mx-auto justify-center">
      <div className="loader-view">
        <Loader />
      </div>
    </div>
  );
};

export default OIDCResponse;
