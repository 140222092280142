import axios from "axios";
import config from "../../config/config";
import { responseHelper, axiosConfig } from "./httpHelper";
import { EVENTS } from "./constants";

const baseUrl = `${config.eventsAPI}${EVENTS}/`;

export default function axiosHttpClientWithoutError(baseURL = baseUrl) {
  const headers = {
    "Content-Type": "application/json",
  };

  const axiosClient = axios.create({
    baseURL: baseURL,
    headers,
  });

  axiosClient.interceptors.request.use(axiosConfig);
  axiosClient.interceptors.response.use(responseHelper);

  return axiosClient;
}
