import config from "config/config";
import userManager from "../config/userManager";
import AuthServices from "utils-auth/authService";

class AuthenticationService {
  signIn() {
    return userManager.signinRedirect();
  }

  async signOut() {
    await AuthServices.logOut();
    return userManager.signoutRedirect();
  }

  changePassword() {
    window.open(config.changePassword, "_blank");
  }
}

export default new AuthenticationService();
