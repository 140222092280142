import React, { createContext, useMemo, useContext } from "react";
import { useQuery } from "react-query";
import { getSaleEvents } from "services-manager/eventsManager";
import TenantsServices from "services-manager/tenantsManager";
import { useAuthContext } from "./AuthProvider";
import { getThemeSettings } from "services-manager/themeSettingsManager";

export const TenantContext = createContext({});

export const useTenantContext = () => useContext(TenantContext);

function TenantProvider(props) {
  const tenantId = localStorage.getItem("tenant_id");
  const { isAuthenticated } = useAuthContext();

  const {
    data: onSaleEventsData,
    isSuccess: isOnSaleEventsSuccess,
    isLoading: isOnSaleEventsLoading,
  } = useQuery([{ skipPaging: true, include: "ticket-types", key: `/api/tenants/${tenantId}/sale-events` }], getSaleEvents, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !!tenantId && isAuthenticated,
  });

  const {
    isLoading: isTenantSettingsLoading,
    data: tenantSettings,
    refetch: refetchTenantSettings,
  } = useQuery([tenantId, { key: `/tenants/${tenantId}/settings` }], TenantsServices.getTenantSettings, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && isAuthenticated,
  });

  const {
    isLoading: isTenantDetailsLoading,
    data: tenantDetails,
    refetch: refetchTenantDetails,
  } = useQuery([tenantId, { key: `/tenants/${tenantId}` }], TenantsServices.getTenantById, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && isAuthenticated,
  });

  const {
    data: themeSettings,
    isLoading: isThemeSettingsLoading,
    isSuccess: isThemeSettingsSuccess,
    refetch: refetchThemeSettings,
  } = useQuery([tenantId, `/api/tenants/${tenantId}/appearance-settings`], getThemeSettings, {
    refetchOnWindowFocus: false,
    enabled: !!tenantId && isAuthenticated,
  });

  const value = useMemo(
    () => ({
      tenantId: tenantId,
      isTenantLoading: isTenantDetailsLoading,
      tenantDetails,
      organizationId: tenantDetails?.organizationId,
      tenantName: tenantDetails?.tenantName,
      timeZone: tenantDetails?.timeZone,
      logo: tenantDetails?.logo,
      eventDomainName: tenantDetails?.eventDomainName,
      locale: tenantDetails?.locale,
      protectionEnabled: tenantDetails?.protectionEnabled,
      tribeEnabled: tenantDetails?.tribeEnabled,
      currency: tenantDetails?.currency,
      refetchTenantDetails: refetchTenantDetails,
      onSaleEvents: isOnSaleEventsSuccess && onSaleEventsData?.items?.length > 0 ? onSaleEventsData.items : [],
      isOnSaleEventsLoading,
      isOnSaleEventsSuccess,
      tenantSettings: tenantSettings,
      isTenantSettingsLoading: isTenantSettingsLoading,
      refetchTenantSettings,
      isThemeSettingsLoading,
      isThemeSettingsSuccess,
      themeSettings,
      refetchThemeSettings,
    }),
    [
      tenantId,
      tenantDetails,
      isTenantDetailsLoading,
      isOnSaleEventsSuccess,
      onSaleEventsData,
      isOnSaleEventsLoading,
      tenantSettings,
      isTenantSettingsLoading,
      themeSettings,
      isThemeSettingsLoading,
      isThemeSettingsSuccess,
    ]
  );

  return <TenantContext.Provider value={value}>{props.children}</TenantContext.Provider>;
}

export default TenantProvider;
