import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import IntlMessages from "util/IntlMessages";
import classNames from "classnames";
import Icons from "components/Common/Icons";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const onPageNumberClick = (data) => {
    onChangePage(data.selected);
  };
  const totalPages = Math.ceil(count / rowsPerPage);
  const containerClass = "flex items-center justify-center list-none mb-0";
  const linkClass = "previousNextClassPagination py-2";
  const disableClass = "disabledClassPagination";
  const isFirstPage = page + 1 === 1;
  const isLastPage = page + 1 === totalPages;

  return (
    <div className="flex pagination-container items-center">
      <ReactPaginate
        previousLabel={
          <span className="w-10 h-10 rounded-full flex items-center justify-center bg-white border border-borderGrey">
            <Icons type="doubleArrowLeft" size="xl" className="text-steelBlue" />
          </span>
        }
        nextLabel={
          <span className="w-10 h-10 rounded-full flex items-center justify-center bg-white border border-borderGrey">
            <Icons type="doubleArrowRight" size="xl" className="text-steelBlue" />
          </span>
        }
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={window.innerWidth > 1199 ? 2 : 1}
        pageRangeDisplayed={window.innerWidth > 1199 ? 3 : 1}
        onPageChange={onPageNumberClick}
        containerClassName="flex items-center justify-center flex-wrap gap-2 list-none mb-0"
        previousClassName={containerClass}
        previousLinkClassName={classNames(linkClass, {
          [disableClass]: isFirstPage,
        })}
        pageLinkClassName={classNames(containerClass, "pageLinkClassPagination typo-14-500")}
        nextClassName={containerClass}
        nextLinkClassName={classNames(linkClass, {
          [disableClass]: isLastPage,
        })}
        breakClassName="flex justify-center w-10"
        activeLinkClassName="activeClassPagination"
        disabledClassName={disableClass}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName={"page-item"}
        forcePage={page}
      />
    </div>
  );
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = TablePaginationActions;

function InlinePagination(props) {
  const { page, rowsPerPage, count, colspan, totalPages, onChangePage, name, className = "" } = props;

  let startCount = page * rowsPerPage + 1;
  let endCount = page * rowsPerPage + rowsPerPage;

  if (endCount > count) endCount = count;
  if (startCount > count) startCount = count;

  // let countText = `${startCount}-${endCount} of ${count}`;

  return (
    <div className={classNames("w-full flex flex-col md:flex-row justify-between items-center gap-3 my-2", className)}>
      <div>
        <span className="flex gap-2 flex-nowrap typo-14-400 text-neutrals4 mr-2">
          <span className="typo-14-500">
            {startCount}-{endCount}
          </span>
          <span> of</span>
          <span className="typo-14-500">{count}</span>
          <span>{name}</span>
        </span>
      </div>
      <div>
        <TablePaginationActionsWrapped colSpan={colspan} totalPages={totalPages} count={count} rowsPerPage={rowsPerPage} page={page} onChangePage={onChangePage} className={"custom-table-input"} />
      </div>
      <div />
    </div>
  );
}

export default InlinePagination;
