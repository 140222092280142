import React, { useState, useMemo, useEffect } from "react";
import { useQuery } from "react-query";
import { getUserTenants } from "services-manager/userTenantsManager";
import IntlMessages from "util/IntlMessages";
import InlinePagination from "../../components/Pagination/InlinePagination";
import TenantCard from "./TenantCard";
import TenantLoader from "./TenantLoader";
import { useAuthContext } from "context/AuthProvider";
import SearchInput from "components/SearchInput";
import { useRouter } from "context/RouteContext";
import BlankSlate from "components/BlankSlate";
import { Simplify } from "conditional-render-simplify";
import UserInfo from "components/UserInfo";

function TenantList() {
  const router = useRouter();
  const { userId, setTenantId } = useAuthContext();

  const [redirect, setRedirect] = useState(false);
  const [take, setTake] = useState(18);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const skip = useMemo(() => page * take, [page, take]);

  const { data, isSuccess, isLoading } = useQuery([userId, { skip, take, searchTerm, key: `/api/users/${userId}/tenants` }], getUserTenants, {
    refetchOnWindowFocus: false,
    enabled: !!userId,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (redirect) {
      const redirectUrl = "/app/dashboard";
      router.push(redirectUrl);
    }
  }, [redirect]);

  const onSelectTenant = (tenantId) => {
    localStorage.setItem("tenant_id", tenantId);
    sessionStorage.setItem("tenant_id", tenantId);
    setTenantId(tenantId);
    setRedirect(true);
  };

  function handleChangeSearch(v) {
    setSearchTerm(v);
    setPage(0);
  }

  return (
    <div className="flex w-full overflow-hidden relative bg-neutrals9">
      <div className="flex flex-column gap-6 w-100 overflow-y-scroll z-10 p-4 h-full">
        <div className="w-full flex flex-col md:flex-row gap-4 items-center">
          <span className="typo-28-600 flex-grow">
            <IntlMessages id="containers.TenantList.yourTenants" />
          </span>
          <div className="flex-shrink-0 flex gap-4">
            <SearchInput value={searchTerm} onChange={(v) => handleChangeSearch(v)} placeholder="placeholder.searchSites" isDisabled={isLoading} />
            <UserInfo />
          </div>
        </div>
        <Simplify
          conditions={{
            loader: isLoading,
            list: isSuccess && data?.items?.length > 0,
            noList: data?.items?.length === 0,
          }}
          loader={<TenantLoader />}
          list={
            <div className="flex flex-col justify-between h-full gap-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center w-full gap-6">
                {data?.items?.map((item, itemIndex) => (
                  <TenantCard key={itemIndex} tenantDetails={item} onSelectTenant={() => onSelectTenant(item.id)} />
                ))}
              </div>
              <InlinePagination
                colspan={3}
                totalPages={data && data.totalPages}
                count={data && data.totalCount}
                rowsPerPage={take}
                page={page}
                onChangePage={(page) => setPage(page)}
                onChangeRowsPerPage={(take) => setTake(take)}
                name={<IntlMessages id="app.components.header.sites" />}
                className="pb-8 md:pb-0"
              />
            </div>
          }
          noList={
            <div className="flex justify-center">
              <BlankSlate view={!!searchTerm ? "noTenantSiteSearch" : "noTenantSite"} className="mt-6 w-3/4" />
            </div>
          }
        />
      </div>
      <img src="./assets/images/site-back.svg" className="absolute w-full" />
      {/* <div className="cross-square-1" />
      <div className="cross-square-2" /> */}
    </div>
  );
}

export default TenantList;
