import { eventsClient } from "./http";

export const getSettingSections = async () => {
  return await eventsClient.get(`/api/themes/setting-sections`);
};

export const getThemeSettings = async (tenantId) => {
  if (tenantId) {
    return await eventsClient.get(`/api/tenants/${tenantId}/appearance-settings`);
  }
  return null;
};

export const updateThemeSettings = async (tenantId, sectionKey, data) => {
  if (tenantId && sectionKey && data) {
    return await eventsClient.post(`/api/tenants/${tenantId}/appearance-settings/${sectionKey}/settings`, data);
  }
  return null;
};
