import { catalogApiClientWithoutError, eventsClient } from "./http";

export const getStatesByCountry = async (countryCode) => {
  const country = countryCode || "";
  return await eventsClient.get(`/api/countries/${country?.toUpperCase()}/state-provinces`);
};

export const getCountries = async () => {
  return await catalogApiClientWithoutError.get(`countries`);
};

export const getTimezones = async () => {
  return await catalogApiClientWithoutError.get(`timezones`);
};

export const getCurrencies = async () => {
  return await catalogApiClientWithoutError.get(`currencies`);
};

export const getLocales = async () => {
  return await catalogApiClientWithoutError.get(`locales`);
};

export const getContinents = async () => {
  return await catalogApiClientWithoutError.get(`continents`);
};

export const getContinentsByCountry = async (continent) => {
  if (!continent) return null;
  let params = { continent: continent };

  return await catalogApiClientWithoutError.get(`countries`, { params: params });
};
